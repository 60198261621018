import type { JsMonomer } from "~/assets/wasm/single/tbnenergy_wasm";
export class Monomer {
  constructor(
    public name: string,
    public sites: string[],
  ) { }

  private _search!: string;
  get searchString() {
    return this._search !== undefined ? this._search : (this._search = this.name + "¦" + this.sites.join("¦"));
  }
}

let monomers: Monomer[] = [];

export function setMonomers(newMonomers: JsMonomer[]) {
  monomers = newMonomers.map((m) => new Monomer(m.name, m.sites));
}

export function getMonomer(index: number) {
  return monomers[index];
}

export function getMonomerIndex(monomer: Monomer) {
  return monomers.indexOf(monomer);
}
