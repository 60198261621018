import type { VoidComponent } from "solid-js";
import { For, Show } from "solid-js";
import { concentrationMultiple } from "~/util/concentration";
import type { Polymer } from "~/util/polymer";
import XIcon from "~icons/heroicons/x-mark-20-solid";
import { useDataStore } from "./DataStore";
import { Tooltip } from "./Tooltip";

export interface PolymerItemProps {
  polymer: Polymer;
}

const numberFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 4,
  minimumFractionDigits: 1,
  trailingZeroDisplay: "stripIfInteger",
  notation: "compact",
});

const scientificFormatter = new Intl.NumberFormat(undefined, {
  maximumFractionDigits: 4,
  minimumFractionDigits: 2,
  notation: "scientific",
});

function formatNumber(value: number) {
  return value != 0 && Math.abs(value) < 1e-3
    ? scientificFormatter.format(value).toLowerCase()
    : numberFormatter.format(value);
}

// table item for polymer list
// arrangement: monomers in polymer, fre_energy, concentration
export const PolymerItem: VoidComponent<PolymerItemProps> = (props) => {
  const dataStore = useDataStore();

  return (
    <>
      <td class="table-cell border-b border-b-zinc-300 px-6 py-4 pt-[1.1rem] text-start align-middle text-base text-gray-600 leading-6 transition-colors duration-100 dark:border-b-zinc-600 dark:text-gray-400 dark:text-gray-400">
        <div class="w-full flex flex-col flex-wrap gap-x-4 gap-y-2">
          <For each={[...props.polymer.monomers.entries()]}>
            {(monomer) => (
              <div class="max-w-full flex items-center text-xs">
                <Show when={monomer[1] > 0}>
                  <span>{monomer[1]}</span>
                  <XIcon class="mt-0.3 inline-block h-3 w-3 shrink-0" />
                </Show>
                <Tooltip
                  placement="right"
                  tooltipText={
                    <div class="inline-flex flex-wrap justify-center gap-1 gap-y-1.5 text-xs text-purple-950 dark:text-purple-100">
                      <For each={monomer[0].sites}>
                        {(site) => (
                          <span class="inline-flex items-center border-1 border-purple-300/80 rounded-full bg-purple-200/50 px-2 py-0.5 font-medium transition-colors duration-100 dark:border-purple-600/80 dark:bg-purple-800/30">
                            {site}
                          </span>
                        )}
                      </For>
                    </div>
                  }
                  as="span"
                  class="truncate rounded-full bg-zinc-300/50 px-2.5 py-0.5 text-zinc-800 font-medium transition-colors duration-100 dark:bg-zinc-700/50 dark:text-zinc-100"
                >
                  {monomer[0].name}
                </Tooltip>
              </div>
            )}
          </For>
        </div>
      </td>
      <td class="table-cell border-b border-b-zinc-300 px-6 py-4 pt-[1.1rem] text-start align-middle text-base leading-6 font-mono dark:border-b-zinc-600">
        {formatNumber(props.polymer.free_energy)}
      </td>
      <td class="w-full table-cell border-b border-b-zinc-300 px-6 py-4 pt-[1.1rem] text-start align-middle text-base leading-6 font-mono dark:border-b-zinc-600">
        <Show when={props.polymer.concentration}>
          <div class="flex items-center gap-1.5">
            <div class="relative h-4 w-1.5 bg-zinc-200 dark:bg-zinc-600">
              <div
                class="absolute bottom-0 left-0 w-full bg-primary-500"
                style={{
                  height: `${(props.polymer.concentration! / dataStore.resultInfo.totalConcentration!) * 100}%`,
                }}
              ></div>
            </div>
            {formatNumber(
              props.polymer.concentration! / concentrationMultiple(dataStore.computeFormData.active.concentrationUnit),
            )}
          </div>
        </Show>
      </td>
    </>
  );
};

/*
<div class="flex flex-col">
  <For each={[...polymer.monomers.entries()]}>
    {(monomer_info) => (
      <span>
        {monomer_info[0].name}: {monomer_info[1]}
      </span>
    )}
  </For>
  <span>Energy: {polymer.free_energy}</span>
  <Show when={polymer.concentration != undefined}>
    <span>Concentration: {polymer.concentration}</span>
  </Show>
</div>
*/
