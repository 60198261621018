// import type { Data as PlotlyData } from "plotly.js-dist-min";
import { createResizeObserver } from "@solid-primitives/resize-observer";
import type * as Plotly from "plotly.js";
import type { VoidComponent } from "solid-js";
import { createEffect, createSignal, on, onMount } from "solid-js";
import type { JSX } from "solid-js/web/types/jsx";

export interface Figure {
  data: Plotly.Data[];
  layout: Partial<Plotly.Layout>;
  frames: Plotly.Frame[] | null;
}

export interface PlotParams {
  data: Plotly.Data[];
  layout: Partial<Plotly.Layout>;
  frames?: Plotly.Frame[] | undefined;
  config?: Partial<Plotly.Config> | undefined;
  /**
   * When provided, causes the plot to update only when the revision is incremented.
   */
  revision?: number | undefined;
  /**
   * Callback executed after plot is initialized.
   * @param figure Object with three keys corresponding to input props: data, layout and frames.
   * @param graphDiv Reference to the DOM node into which the figure was rendered.
   */
  onInitialized?: ((figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => void) | undefined;
  /**
   * Callback executed when when a plot is updated due to new data or layout, or when user interacts with a plot.
   * @param figure Object with three keys corresponding to input props: data, layout and frames.
   * @param graphDiv Reference to the DOM node into which the figure was rendered.
   */
  onUpdate?: ((figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => void) | undefined;
  /**
   * Callback executed when component unmounts, before Plotly.purge strips the graphDiv of all private attributes.
   * @param figure Object with three keys corresponding to input props: data, layout and frames.
   * @param graphDiv Reference to the DOM node into which the figure was rendered.
   */
  onPurge?: ((figure: Readonly<Figure>, graphDiv: Readonly<HTMLElement>) => void) | undefined;
  /**
   * Callback executed when a plotly.js API method rejects
   * @param err Error
   */
  onError?: ((err: Readonly<Error>) => void) | undefined;
  /**
   * id assigned to the <div> into which the plot is rendered.
   */
  divId?: string | undefined;
  /**
   * applied to the <div> into which the plot is rendered
   */
  class?: string | undefined;
  /**
   * used to style the <div> into which the plot is rendered
   */
  style?: JSX.CSSProperties | undefined;

  onAfterExport?: (() => void) | undefined;
  onAfterPlot?: (() => void) | undefined;
  onAnimated?: (() => void) | undefined;
  onAnimatingFrame?: ((event: Readonly<Plotly.FrameAnimationEvent>) => void) | undefined;
  onAnimationInterrupted?: (() => void) | undefined;
  onAutoSize?: (() => void) | undefined;
  onBeforeExport?: (() => void) | undefined;
  onBeforeHover?: ((event: Readonly<Plotly.PlotMouseEvent>) => boolean) | undefined;
  onButtonClicked?: ((event: Readonly<Plotly.ButtonClickEvent>) => void) | undefined;
  onClick?: ((event: Readonly<Plotly.PlotMouseEvent>) => void) | undefined;
  onClickAnnotation?: ((event: Readonly<Plotly.ClickAnnotationEvent>) => void) | undefined;
  onDeselect?: (() => void) | undefined;
  onDoubleClick?: (() => void) | undefined;
  onFramework?: (() => void) | undefined;
  onHover?: ((event: Readonly<Plotly.PlotHoverEvent>) => void) | undefined;
  onLegendClick?: ((event: Readonly<Plotly.LegendClickEvent>) => boolean) | undefined;
  onLegendDoubleClick?: ((event: Readonly<Plotly.LegendClickEvent>) => boolean) | undefined;
  onRelayout?: ((event: Readonly<Plotly.PlotRelayoutEvent>) => void) | undefined;
  onRestyle?: ((event: Readonly<Plotly.PlotRestyleEvent>) => void) | undefined;
  onRedraw?: (() => void) | undefined;
  onSelected?: ((event: Readonly<Plotly.PlotSelectionEvent>) => void) | undefined;
  onSelecting?: ((event: Readonly<Plotly.PlotSelectionEvent>) => void) | undefined;
  onSliderChange?: ((event: Readonly<Plotly.SliderChangeEvent>) => void) | undefined;
  onSliderEnd?: ((event: Readonly<Plotly.SliderEndEvent>) => void) | undefined;
  onSliderStart?: ((event: Readonly<Plotly.SliderStartEvent>) => void) | undefined;
  onTransitioning?: (() => void) | undefined;
  onTransitionInterrupted?: (() => void) | undefined;
  onUnhover?: ((event: Readonly<Plotly.PlotMouseEvent>) => void) | undefined;
  onWebGlContextLost?: (() => void) | undefined;
}

const eventNames = [
  "AfterExport",
  "AfterPlot",
  "Animated",
  "AnimatingFrame",
  "AnimationInterrupted",
  "AutoSize",
  "BeforeExport",
  "BeforeHover",
  "ButtonClicked",
  "Click",
  "ClickAnnotation",
  "Deselect",
  "DoubleClick",
  "Framework",
  "Hover",
  "LegendClick",
  "LegendDoubleClick",
  "Relayout",
  "Relayouting",
  "Restyle",
  "Redraw",
  "Selected",
  "Selecting",
  "SliderChange",
  "SliderEnd",
  "SliderStart",
  "SunburstClick",
  "Transitioning",
  "TransitionInterrupted",
  "Unhover",
  "WebGlContextLost",
];

const updateEvents = [
  "plotly_restyle",
  "plotly_redraw",
  "plotly_relayout",
  "plotly_relayouting",
  "plotly_doubleclick",
  "plotly_animated",
  "plotly_sunburstclick",
];

export const PlotlyPlot: VoidComponent<PlotParams> = (props) => {
  const [plotRef, setPlotRef] = createSignal<HTMLDivElement>();

  const [Plotly, setPlotly] = createSignal<typeof import("plotly.js")>();

  const handlers = new Map<string, EventListener>();

  function getPlotlyEventName(eventName: string) {
    return "plotly_" + eventName.toLowerCase();
  }

  createEffect(() => {
    // if any of the event handlers are defined, attach them to the plot
    eventNames.forEach((eventName) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handler = (props as Record<string, any>)["on" + eventName] as EventListener | undefined;
      // check to see if we already have a handler for this event
      const existingHandler = handlers.get(eventName);
      if (existingHandler && existingHandler !== handler) {
        // if so, remove it
        plotRef()!.removeEventListener(getPlotlyEventName(eventName), existingHandler);
      }
      if (handler && handler !== existingHandler) {
        // add the new handler
        handlers.set(eventName, handler);
        plotRef()!.addEventListener(getPlotlyEventName(eventName), handler);
      }
    });
  });

  onMount(async () => {
    // @ts-expect-error -- dumb dumb
    setPlotly(await import("plotly.js-dist"));

    await Plotly()!.newPlot(plotRef()!, props.data, props.layout, props.config);

    if (typeof props.onInitialized === "function") {
      // @ts-expect-error -- plotly injects parameters into the html element
      const { data, layout }: { data: Plotly.Data[]; layout: Plotly.Layout } = plotRef()!;
      // @ts-expect-error -- plotly injects parameters into the html element
      // eslint-disable-next-line
      const frames: Plotly.Frame[] = plotRef()!._transitionData ? plotRef()!._transitionData._frames : null;
      const figure: Figure = { data, layout, frames };
      props.onInitialized(figure, plotRef()!);
    }

    return () => {
      // remove all handlers
      for (const handler of handlers.values()) {
        plotRef()!.removeEventListener(getPlotlyEventName(handler.name), handler);
      }

      Plotly()!.purge(plotRef()!);
    };
  });

  createEffect(
    on(
      () => [Plotly, props.data, props.layout, props.config],
      async () => {
        if (!Plotly()) return;
        await Plotly()?.react(plotRef()!, props.data, props.layout, props.config);
      },
      {
        defer: true,
      },
    ),
  );

  createResizeObserver(
    () => plotRef(),
    () => {
      Plotly()?.Plots?.resize(plotRef()!);
    },
  );

  return <div ref={setPlotRef} class={props.class}></div>;
};
