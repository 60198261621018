import { defaultKeymap, history, historyKeymap } from "@codemirror/commands";
import { HighlightStyle, syntaxHighlighting } from "@codemirror/language";
import { EditorView, keymap, lineNumbers } from "@codemirror/view";
import { tags } from "@lezer/highlight";
import clsx from "clsx";
import { createCodeMirror } from "solid-codemirror";
import { splitProps, type JSX, type VoidComponent } from "solid-js";
import { theme as tailwindTheme } from "unocss/preset-mini";
import { useTheme } from "../ThemeController";
import { langTbnEnergies, langTbnMonomers } from "./lang-tbn";

interface EditorProps {
  onValueChange?: (value: string) => void;
  value?: string;
  lang: "monomers" | "energies";
}

export const Editor: VoidComponent<EditorProps & JSX.HTMLAttributes<HTMLDivElement>> = (props) => {
  const { theme: pageTheme } = useTheme();
  const [_, rest] = splitProps(props, ["onValueChange", "value", "lang", "class"]);

  const {
    editorView,
    ref: editorRef,
    createExtension,
  } = createCodeMirror({
    /**
     * The initial value of the editor
     */
    value: props.value,
    onValueChange: props.onValueChange,
  });

  const baseTheme = EditorView.theme({
    "&": {
      textAlign: "left",
      outline: "none",
    },
    ".cm-content": {
      textAlign: "left",
    },
    ".cm-gutters": {
      backgroundColor: "transparent",
      border: "none",
    },
    ".cm-lineNumbers": {
      position: "sticky",
      flexDirection: "column",
      flexShrink: 0,
    },
    ".cm-lineNumbers .cm-gutterElement": {
      textAlign: "right",
      padding: "0 12px 0 0px",
      lineHeight: "21px",
    },
    ".cm-line": {
      padding: "1px 0 1px",
    },
    ".cm-cursor": {
      borderLeftWidth: "2px",
      height: "21px",
      transform: "translateY(-10%)",
    },
    "&.cm-focused": {
      outline: "none !important",
    },
  });

  const lightTheme = EditorView.theme({
    "&": {
      color: tailwindTheme.colors.zinc[900],
    },
  });

  const darkTheme = EditorView.theme(
    {
      "&": {
        color: tailwindTheme.colors.zinc[100],
      },
    },
    {
      dark: true,
    },
  );

  const lightHighlightStyle = HighlightStyle.define(
    [
      { tag: tags.variableName, fontStyle: "italic" },
      { tag: tags.keyword, color: "rgb(114, 135, 253)" },
      { tag: tags.number, color: "rgb(64, 160, 43)" },
      { tag: tags.comment, color: "rgb(92, 95, 119)" },
    ],
    {
      themeType: "light",
    },
  );

  const darkHighlightStyle = HighlightStyle.define(
    [
      { tag: tags.variableName, fontStyle: "italic" },
      { tag: tags.keyword, color: "rgb(186, 187, 241)" },
      { tag: tags.number, color: "rgb(166, 209, 137)" },
      { tag: tags.comment, color: "rgb(181, 189, 220)" },
    ],
    {
      themeType: "dark",
    },
  );

  const baseEditorExtensions = [
    [
      baseTheme,
      syntaxHighlighting(lightHighlightStyle),
      syntaxHighlighting(darkHighlightStyle),
      props.lang === "monomers" ? langTbnMonomers : langTbnEnergies,
      lineNumbers(),
      history(),
      keymap.of([...defaultKeymap, ...historyKeymap]),
    ],
  ];

  createExtension(() => (pageTheme() == "light" ? lightTheme : darkTheme));
  createExtension(baseEditorExtensions);
  createExtension(EditorView.contentAttributes.of({ "aria-label": "Codemirror editor" }));
  return (
    <div
      ref={editorRef}
      class={clsx(
        "mb-6 min-h-12.5 border-1 border-neutral-300 rounded-lg rounded-lg bg-neutral-50 p-2.5 outline-none dark:border-neutral-600 dark:bg-neutral-700 focusable-form flex items-stretch children:flex-1",
        props.class,
      )}
      {...rest}
    />
  );
};
