export function concentrationMultiple(concentration: string) {
  switch (concentration) {
    case "mM":
      return 1e-3;
    case "µM":
      return 1e-6;
    case "nM":
      return 1e-9;
    case "pM":
      return 1e-12;
    default:
      throw new Error(`Unknown concentration unit: ${concentration}`);
  }
}
