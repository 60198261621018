// This file was generated by lezer-generator. You probably shouldn't edit it.
import { LRParser } from "@lezer/lr";
export const parser = LRParser.deserialize({
  version: 14,
  states:
    "!jQYQPOOOOQO'#C_'#C_ObQPO'#CeOOQO'#Ce'#CeOOQO'#Ca'#CaQYQPOOOgQPO'#CfOOQO,59P,59POOQO-E6_-E6_OlQQO,59QOOQO'#C`'#C`OOQO1G.l1G.l",
  stateData: "q~OWOSPOS~OZPO^RO~O^VO~O[XO~O]YO~O",
  goto: "qZPPP[`cPPPimTUOTRZXQTORWTTSOTTQOT",
  nodeNames: "⚠ LineComment Program RegularSite Concentration",
  maxTerm: 14,
  skippedNodes: [0, 1],
  repeatNodeCount: 1,
  tokenData:
    "%^~RbXY!ZYZ#OZ[!Zpq!Zst#T}!O#l!Q![$f!_!`%X!c!}$Q#R#S$Q#T#o$Q$f$g!Z#BY#BZ!Z$IS$I_!Z$JT$JU!Z$KV$KW!Z&FU&FV!Z?HT?HU!Z~!`YW~XY!ZZ[!Zpq!Z$f$g!Z#BY#BZ!Z$IS$I_!Z$JT$JU!Z$KV$KW!Z&FU&FV!Z?HT?HU!Z~#TO^~~#YSP~OY#TZ;'S#T;'S;=`#f<%lO#T~#iP;=`<%l#TR#qTZP}!O$Q!Q![$f!c!}$Q#R#S$Q#T#o$QP$VTZP}!O$Q!Q![$Q!c!}$Q#R#S$Q#T#o$QR$mUZP]Q}!O$Q!O!P%P!Q![$f!c!}$Q#R#S$Q#T#o$QQ%UP]Q!Q![%P~%^O[~",
  tokenizers: [0, 1],
  topRules: { Program: [0, 2] },
  tokenPrec: 0,
});
