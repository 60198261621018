import type { Monomer } from "./monomer";
import { getMonomer } from "./monomer";

export class Polymer {
  constructor(
    public monomers: Map<Monomer, number>,
    public free_energy: number,
    public concentration?: number,
  ) { }

  static fromComplex(monomerCounts: number[], free_energy: number) {
    const monomers = new Map<Monomer, number>();

    const length = monomerCounts.length;
    for (let monomerIndex = 0; monomerIndex < length; monomerIndex++) {
      const count = monomerCounts[monomerIndex];
      if (count > 0) {
        monomers.set(getMonomer(monomerIndex), count);
      }
    }

    return new Polymer(monomers, free_energy);
  }

  toJSON() {
    return {
      monomers: [...this.monomers.entries()].map(([monomer, count]) => [monomer, count]),
      free_energy: this.free_energy,
      concentration: this.concentration,
    };
  }

  private _search!: string;
  get searchString() {
    return this._search !== undefined ? this._search : (this._search = searchifyPolymer(this));
  }
}

function searchifyPolymer(poly: Polymer) {
  const items = [];
  for (const monomer of poly.monomers.keys()) {
    items.push(monomer.searchString);
  }
  return items.join("¦");
}
