import uFuzzy from "@leeoniya/ufuzzy";
import { debounce } from "@solid-primitives/scheduled";
import { Index, Show, createEffect, createMemo, createSignal, on, untrack, type VoidComponent } from "solid-js";
import { createStore, unwrap } from "solid-js/store";
import { concentrationMultiple } from "~/util/concentration";
import { getMonomerIndex, type Monomer } from "~/util/monomer";
import type { Polymer } from "~/util/polymer";
import SearchIcon from "~icons/heroicons/magnifying-glass-20-solid";
import { useDataStore } from "./DataStore";

import { makeEventListener } from "@solid-primitives/event-listener";
import { createWindowVirtualizer } from "@tanstack/solid-virtual";
import ComplexIcon from "~icons/heroicons/hashtag-20-solid";
import ConcentrationIcon from "~icons/heroicons/sparkles-20-solid";
import { PolymerItem } from "./PolymerItem";
import { Tooltip } from "./Tooltip";

interface OutputProps {
  polymers: Polymer[];
}

// number of items to start rendering before infinite scrolling
const DEFAULT_RENDER_LENGTH = 30;

// persist search between operations
const [search, _setSearch] = createSignal("");

export const Output: VoidComponent<OutputProps> = (props) => {
  const dataStore = useDataStore();
  const formData = dataStore.computeFormData.active;
  const setSearch = debounce((searchValue: string) => _setSearch(searchValue), 250);
  const [searchRef, setSearchRef] = createSignal<HTMLInputElement>();

  const fuzzy = new uFuzzy();

  const monomerSet = createMemo(() => {
    const monomers = new Set<Monomer>();
    for (const poly of props.polymers) {
      for (const monomer of poly.monomers.keys()) {
        monomers.add(monomer);
      }
    }
    return Array.from(monomers);
  });

  const searchableMonomers = createMemo(() => {
    return monomerSet().map((m) => m.searchString);
  });

  const searchablePolymers = createMemo(() => {
    const searchPolymers = new Array<string>(props.polymers.length);
    for (let i = 0; i < props.polymers.length; i++) {
      searchPolymers[i] = props.polymers[i].searchString;
    }

    return searchPolymers;
  });

  const result = createMemo(() => {
    const searchValue = search();

    if (searchValue.length == 0) return props.polymers;

    if (search().includes(",")) {
      const haystack = searchableMonomers();

      const matches: Map<Monomer, number> = new Map();
      for (let monomer of searchValue.split(",")) {
        monomer = monomer.trim();
        if (monomer.length == 0) continue;
        const indexes = fuzzy.filter(haystack, monomer);
        if (indexes != null && indexes.length > 0) {
          const info = fuzzy.info(indexes, haystack, monomer);
          const monomerIndex = info.idx[fuzzy.sort(info, haystack, monomer)[0]];
          const matchedMonomer = monomerSet()[monomerIndex];
          matches.set(matchedMonomer, (matches.get(matchedMonomer) ?? 0) + 1);
        }
      }

      return untrack(() => {
        const complexes = unwrap(dataStore.rawData().complexes);
        let complexIdxs = complexes.map((_, i) => i);

        for (const [monomer, count] of matches.entries()) {
          const index = getMonomerIndex(monomer);

          const filtered = [];
          // iterate through each polymer and remove it if it doesn't have the monomer
          for (const i of complexIdxs) {
            if (complexes[i][index] >= count) {
              filtered.push(i);
            }
          }

          complexIdxs = filtered;
        }

        return complexIdxs.map((i) => props.polymers[i]);
      });
    } else {
      const haystack = searchablePolymers();
      const [indexes] = fuzzy.search(haystack, searchValue, 1);
      if (indexes == null || indexes.length <= 0) return [];
      return indexes.map((i) => untrack(() => props.polymers[i]));
    }
  });

  const searchedConcentration = createMemo(() =>
    dataStore.computeFormData.currentStep > 2
      ? result().reduce((acc, poly) => acc + poly.concentration!, 0)
      : undefined,
  );

  const [renderLength, setRenderLength] = createSignal(DEFAULT_RENDER_LENGTH);
  const [tableBodyRef, setTableBodyRef] = createSignal<HTMLTableSectionElement>();
  const [virtualSizeCache, setVirtualSizeCache] = createStore<number[]>([]);

  const [tableScrollOffset, setTableScrollOffset] = createSignal<number | undefined>(undefined);

  function instantiateScrollOffset() {
    setTableScrollOffset((tableBodyRef()?.offsetParent as HTMLElement)?.offsetTop);
  }

  makeEventListener(window, "resize", instantiateScrollOffset, { passive: true });
  createEffect(instantiateScrollOffset);

  const virtualizer = createMemo(() => {
    return createWindowVirtualizer({
      count: Math.min(result().length, renderLength()),
      estimateSize: (i) => untrack(() => virtualSizeCache[i]) ?? 120,
      overscan: 2,
      scrollMargin: tableScrollOffset(),
    });
  });

  // reset render length when result changes
  createEffect(
    on(result, () => {
      if (result().length < renderLength()) {
        setRenderLength(DEFAULT_RENDER_LENGTH);
      }
    }),
  );

  return (
    <>
      <div class="mb-1 mt-4 w-full flex flex-justify-between px-5 text-sm text-zinc-500 font-mono dark:text-zinc-400">
        <Tooltip
          as="div"
          tooltipText="Number of complexes displayed"
          placement="top"
          class="flex-inline items-center gap-1"
        >
          <ComplexIcon class="h-4 w-4" /> {result().length.toLocaleString()}
        </Tooltip>
        <Tooltip
          as="div"
          tooltipText={`Total concentration of complexes displayed (${formData.concentrationUnit})`}
          placement="top"
          class="flex-inline items-center gap-1"
        >
          <Show when={searchedConcentration() !== undefined}>
            <>
              {(searchedConcentration()! / concentrationMultiple(formData.concentrationUnit)).toFixed(1)}
              <ConcentrationIcon class="h-4 w-4" />
            </>
          </Show>
        </Tooltip>
      </div>
      <div
        class="mb-4 h-10 w-full flex cursor-text items-center gap-2 border-2 border-zinc-200 rounded-lg bg-white px-4 transition-colors duration-100 dark:border-zinc-700 group-focus-visible:border-blue-500 hover:border-zinc-400 dark:bg-zinc-800 dark:group-focus-visible:border-blue-500 dark:hover:border-zinc-600"
        classList={{
          "border-secondary-500! dark:border-secondary-600!": !!search(),
        }}
        onClick={() => {
          searchRef()?.focus();
        }}
      >
        <SearchIcon class="h-5 w-5" />
        <input
          ref={setSearchRef}
          value={search()}
          onInput={(e) => {
            setSearch(e.currentTarget.value);
          }}
          class="flex-1 bg-transparent outline-none placeholder-zinc-500/80 dark:placeholder-zinc-400"
          type="text"
          placeholder="Search..."
        />
      </div>
      <div class="contain-paint w-full scroll-mt-60vh overflow-x-auto border-2 border-zinc-200 rounded-lg md:overflow-visible dark:border-zinc-700">
        <table class="grid min-w-full border-separate border-spacing-0 transition-colors duration-100 dark:bg-zinc-800/20 dark:text-zinc-50">
          <thead class="sticky z-1 grid w-full drop-shadow md:top-16 dark:drop-shadow-color-zinc-900">
            <tr class="grid cols-3 bg-white text-xs text-zinc-700 font-semibold leading-4 tracking-wider uppercase transition-colors duration-100 dark:bg-zinc-800 dark:text-zinc-300">
              <th class="w-full border-b border-b-zinc-300 px-6 py-3 text-start dark:border-zinc-600">Complex</th>
              <th class="w-full border-b border-b-zinc-300 px-6 py-3 text-start dark:border-zinc-600">Free Energy</th>
              <th class="w-full border-b border-b-zinc-300 px-6 py-3 text-start dark:border-zinc-600">
                Concentration{" "}
                <span class="text-zinc-500 case-normal dark:text-gray-400">({formData.concentrationUnit})</span>
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              height: `${Math.max(virtualizer().getTotalSize(), 0)}px`,
              position: "relative",
            }}
            ref={(el) => queueMicrotask(() => setTableBodyRef(el))}
          >
            <Index each={virtualizer().getVirtualItems()}>
              {(virtualItem) => {
                const [itemRef, setItemRef] = createSignal<HTMLElement>();
                createEffect(
                  on([virtualItem], () => {
                    const el = itemRef();
                    const item = virtualItem();
                    if (el) item.measureElement(el);
                    setVirtualSizeCache(item.index, item.size);

                    if (item.index > renderLength() - 10) {
                      setRenderLength(renderLength() + 30);
                    }
                  }),
                );
                return (
                  <tr
                    class="absolute left-0 top-0 grid cols-3 w-full"
                    style={{
                      transform: `translateY(${virtualItem().start - virtualizer().options.scrollMargin}px)`,
                    }}
                    data-index={virtualItem().index}
                    ref={setItemRef}
                  >
                    <PolymerItem polymer={result()[virtualItem().index]} />
                  </tr>
                );
              }}
            </Index>
          </tbody>
        </table>
      </div>
      {/* <Show when={result().length > renderLength()}>
        <p class="mt-1 text-zinc-500 dark:text-zinc-400">... and {result().length - renderLength()} more</p>
      </Show> */}
    </>
  );
};
