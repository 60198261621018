import { Show, createSignal } from "solid-js";
import { isServer } from "solid-js/web";
import { PlotlyPlot } from "./PlotlyPlot";
import { FastSpinner } from "./Spinner";
import { useTheme } from "./ThemeController";

export const Histogram = (props: { values: number[]; class?: string }) => {
  const sharedLayout: Partial<Plotly.Layout> = {
    colorway: [
      "#636efa",
      "#EF553B",
      "#00cc96",
      "#ab63fa",
      "#FFA15A",
      "#19d3f3",
      "#FF6692",
      "#B6E880",
      "#FF97FF",
      "#FECB52",
    ],
    // paper_bgcolor: "transparent",
    plot_bgcolor: "transparent",
    bargap: 0.08,
    autosize: true,
    margin: {
      l: 30,
      r: 20,
      b: 20,
      t: 30,
    },
    xaxis: {
      // rangeslider: {},
    },
    yaxis: {
      type: "log",
      autorange: true,
      fixedrange: true,
    },
  };

  const plotlyDark: Partial<Plotly.Layout> = {
    ...sharedLayout,
    font: { color: "#f2f5fa" },
    hovermode: "closest",
    hoverlabel: { align: "left" },
    paper_bgcolor: "rgb(20,20,22)",
    xaxis: {
      gridcolor: "#283442",
      linecolor: "#506784",
      ticks: "",
      title: { standoff: 15 },
      zerolinecolor: "#283442",
      automargin: true,
      zerolinewidth: 2,
      ...sharedLayout.xaxis,
    },
    yaxis: {
      gridcolor: "#283442",
      linecolor: "#506784",
      ticks: "",
      title: { standoff: 15 },
      zerolinecolor: "#283442",
      automargin: true,
      zerolinewidth: 2,
      ...sharedLayout.yaxis,
    },
  };

  const plotlyLight: Partial<Plotly.Layout> = {
    ...sharedLayout,
    font: { color: "#2a3f5f" },
    hovermode: "closest",
    hoverlabel: { align: "left" },
    paper_bgcolor: "white",
    xaxis: {
      gridcolor: "#EBF0F8",
      linecolor: "#EBF0F8",
      ticks: "",
      title: { standoff: 15 },
      zerolinecolor: "#EBF0F8",
      automargin: true,
      zerolinewidth: 2,
      ...sharedLayout.xaxis,
    },
    yaxis: {
      gridcolor: "#EBF0F8",
      linecolor: "#EBF0F8",
      ticks: "",
      title: { standoff: 15 },
      zerolinecolor: "#EBF0F8",
      automargin: true,
      zerolinewidth: 2,
      ...sharedLayout.yaxis,
    },
  };

  const { theme } = useTheme();

  const [isLoading, setIsLoading] = createSignal(true);

  return (
    <div class="relative">
      <Show when={!isServer}>
        <PlotlyPlot
          class={props.class}
          onInitialized={() => {
            setIsLoading(false);
          }}
          layout={theme() == "dark" ? plotlyDark : plotlyLight}
          data={[
            {
              x: props.values,
              type: "histogram",
              xbins: {
                end: props.values.reduce((a, b) => Math.max(a, b), 0),
                size: 1,
                start: props.values.reduce((a, b) => Math.min(a, b), 0),
              },
            },
          ]}
          config={{
            displayModeBar: false,
          }}
        />
      </Show>
      <FastSpinner
        show={isLoading()}
        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 h-8 w-8 dark:text-neutral-500 text-neutral-400"
      />
    </div>
  );
};
