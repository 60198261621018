// This file was generated by lezer-generator. You probably shouldn't edit it.
import { LRParser } from "@lezer/lr";
export const parser = LRParser.deserialize({
  version: 14,
  states:
    "$bQYQPOOOeQPO'#C_OOQO'#Cl'#ClOOQO'#Cd'#CdOsQPO'#CiO{QQO'#CiO!QQPO'#CiO!VQPO'#ChOOQO'#Ch'#ChOOQO'#Cc'#CcQYQPOOOOQO,58{,58{O![QPO'#C`OOQO-E6b-E6bO{QQO,59TOOQO'#Cb'#CbOOQO,59T,59TO!gQPO,59TOOQO,59S,59SOOQO-E6a-E6aOOQO1G.o1G.oO!oQPO1G.oO{QQO1G.oO{QQO7+$ZOOQO7+$Z7+$ZOOQO<<Gu<<Gu",
  stateData: "!w~OZOSPOS~O^PObTOdWO~OaZO^SX_RXbSX~O^[Ob^O~Oc_O~O_aO~OdbO~OaZO^SXbSX~O^[ObfO~O^[ObgO~O",
  goto: "!kaPPPbffmy!PPPP![!`PP!dTUOYZQOSYaeQ`TQd^QhfRigQYORcYSSOYS]SeReaTXOYTVOYZROSYae",
  nodeNames: "⚠ LineComment Program Name RegularSite StarredSite Concentration",
  maxTerm: 20,
  skippedNodes: [0, 1],
  repeatNodeCount: 2,
  tokenData:
    "%n~RdXY!aYZ#UZ[!apq!ast#Zz{#r|}#w}!O#|!Q![$v![!]%i!c!}$b#R#S$b#T#o$b$f$g!a#BY#BZ!a$IS$I_!a$JT$JU!a$KV$KW!a&FU&FV!a?HT?HU!a~!fYZ~XY!aZ[!apq!a$f$g!a#BY#BZ!a$IS$I_!a$JT$JU!a$KV$KW!a&FU&FV!a?HT?HU!a~#ZOd~~#`SP~OY#ZZ;'S#Z;'S;=`#l<%lO#Z~#oP;=`<%l#Z~#wOa~~#|Ob~R$RT^P}!O$b!Q![$v!c!}$b#R#S$b#T#o$bP$gT^P}!O$b!Q![$b!c!}$b#R#S$b#T#o$bR$}U^PcQ}!O$b!O!P%a!Q![$v!c!}$b#R#S$b#T#o$bQ%fPcQ!Q![%a~%nO_~",
  tokenizers: [0, 1],
  topRules: { Program: [0, 2] },
  tokenPrec: 0,
});
