import type { Accessor, JSX, ParentProps } from "solid-js";
import {
  For,
  Match,
  Show,
  Switch,
  children,
  createContext,
  createEffect,
  createSignal,
  splitProps,
  useContext,
} from "solid-js";
import CheckIcon from "~icons/heroicons/check-20-solid";
import { FastSpinner } from "./Spinner";

const StepsContext = createContext<Accessor<unknown>>();

interface StepsRootProps<T> extends JSX.HTMLAttributes<HTMLOListElement> {
  step: T;
  loading?: boolean;
  children: ReturnType<typeof StepsNode<T>>[];
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function StepsRoot<T extends number>(props: StepsRootProps<Exclude<T, Function>>) {
  const [currentStep, setCurrentStep] = createSignal<T>(props.step);
  const [local, others] = splitProps(props, ["step", "loading", "children"]);

  local.step;

  createEffect(() => {
    setCurrentStep(local.step);
  });

  return (
    <StepsContext.Provider value={currentStep}>
      <ol role="list" {...others}>
        {(() => {
          const c = children(() => local.children);
          return (
            <For each={c.toArray()}>
              {(child, i) => {
                return (
                  <li
                    class="group relative"
                    classList={{
                      "pb-10": i() < c.toArray().length - 1,
                    }}
                  >
                    <Show when={i() < c.toArray().length - 1}>
                      <div
                        class="absolute left-4 top-4 m-0.5 h-full w-0.5 transition-colors duration-100 -ml-px"
                        classList={{
                          "bg-primary-500 dark:bg-primary-600": i() + 1 < currentStep(),
                          "bg-zinc-300 dark:bg-zinc-700": i() + 1 >= currentStep(),
                        }}
                        aria-hidden="true"
                      ></div>
                    </Show>
                    <div class="relative flex items-center">
                      <span
                        class="relative z-10 h-8 w-8 flex items-center justify-center border-2 border-current rounded-full transition-colors duration-100"
                        classList={{
                          "text-primary-500 dark:text-primary-600 group-hover:text-primary-400 dark:group-hover:text-primary-700":
                            i() + 1 <= currentStep(),
                          "!bg-current": i() + 1 < currentStep(),
                          "bg-zinc-50 dark:bg-zinc-900": i() + 1 >= currentStep(),
                          "text-zinc-300 dark:text-zinc-700 group-hover:text-zinc-400 dark:group-hover:text-zinc-600":
                            i() + 1 > currentStep(),
                        }}
                      >
                        <Switch
                          fallback={
                            <span
                              class="h-2.5 w-2.5 rounded-full bg-current"
                              classList={{
                                "opacity-0 group-hover:opacity-100": i() + 1 > currentStep(),
                              }}
                            ></span>
                          }
                        >
                          <Match when={i() + 1 == currentStep() && local.loading}>
                            <FastSpinner show class="h-5 w-5" />
                          </Match>
                          <Match when={i() + 1 < currentStep()}>
                            <CheckIcon class="h-5 w-5 text-white" />
                          </Match>
                        </Switch>
                      </span>
                      <span class="ml-4 min-w-0 flex flex-col">{child}</span>
                    </div>
                  </li>
                );
              }}
            </For>
          );
        })()}
      </ol>
    </StepsContext.Provider>
  );
}

export function StepsNode<T>(props: ParentProps<{ step: T } & JSX.HTMLAttributes<HTMLDivElement>>) {
  const currentStep = useContext(StepsContext);
  if (!currentStep) throw new Error("StepsNode must be used within a StepsRoot");

  const [local, others] = splitProps(props, ["step"]);

  return <div {...others}></div>;
}

export const Steps = {
  Root: StepsRoot,
  Node: StepsNode,
};
