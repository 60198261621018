import {
  FORM_CONTROL_FIELD_PROP_NAMES,
  Polymorphic,
  createFormControlField,
  useFormControlContext,
  useTextFieldContext,
  type AsChildProp,
} from "@kobalte/core";
import type { OverrideComponentProps } from "@kobalte/utils";
import { composeEventHandlers, mergeDefaultProps } from "@kobalte/utils";
import type { JSX } from "solid-js";
import { splitProps } from "solid-js";

export interface NumberFieldInputProps extends OverrideComponentProps<"input", AsChildProp> {}

/**
 * The native html input of the textfield.
 */
export function NumberFieldInput(props: NumberFieldInputProps) {
  return <NumberFieldInputBase type="number" {...props} />;
}

function isValidNumericEvent(event: KeyboardEvent) {
  if (event.key == null) return true;
  const isModifier = event.ctrlKey || event.altKey || event.metaKey;
  const isSingleKey = event.key.length === 1;
  if (isModifier || !isSingleKey) return true;
  return /^[0-9+\-.]$/.test(event.key);
}

const isNumericChar = (e: string) => /^[0-9+\-.]$/.test(e);

const handleBlur: JSX.FocusEventHandlerUnion<HTMLInputElement, FocusEvent> = (event) => {
  const value = event.currentTarget.value;
  event.currentTarget.value = value.split("").filter(isNumericChar).join("");
};

export function NumberFieldInputBase(props: NumberFieldInputProps) {
  const formControlContext = useFormControlContext();
  const context = useTextFieldContext();

  props = mergeDefaultProps(
    {
      id: context.generateId("input"),
    },
    props,
  );

  const [local, formControlFieldProps, others] = splitProps(props, ["onInput"], FORM_CONTROL_FIELD_PROP_NAMES);

  const { fieldProps } = createFormControlField(formControlFieldProps);

  return (
    <Polymorphic
      as="input"
      id={fieldProps.id()}
      name={formControlContext.name()}
      value={context.value()}
      required={formControlContext.isRequired()}
      disabled={formControlContext.isDisabled()}
      readonly={formControlContext.isReadOnly()}
      aria-label={fieldProps.ariaLabel()}
      aria-labelledby={fieldProps.ariaLabelledBy()}
      aria-describedby={fieldProps.ariaDescribedBy()}
      aria-invalid={formControlContext.validationState() === "invalid" || undefined}
      aria-required={formControlContext.isRequired() || undefined}
      aria-disabled={formControlContext.isDisabled() || undefined}
      aria-readonly={formControlContext.isReadOnly() || undefined}
      onInput={composeEventHandlers([local.onInput])}
      onBlur={composeEventHandlers([handleBlur, context.onInput])}
      onKeyDown={(event: KeyboardEvent) => {
        if (event.isComposing) return;

        if (!isValidNumericEvent(event)) {
          event.preventDefault();
        }
      }}
      {...formControlContext.dataset()}
      {...others}
    />
  );
}
