import { LRLanguage, LanguageSupport } from "@codemirror/language";
import { styleTags, tags as t } from "@lezer/highlight";
import { parser as energiesParser } from "./parser-energies";
import { parser as monomersParser } from "./parser-monomers";

const style = styleTags({
  Name: t.variableName,
  RegularSite: t.keyword,
  StarredSite: t.keyword,
  LineComment: t.lineComment,
  Concentration: t.number,
});

const monomersParserWithMetadata = monomersParser.configure({
  props: [style],
});

const monomersLanguage = LRLanguage.define({
  parser: monomersParserWithMetadata,
  languageData: {
    commentTokens: { line: "#" },
  },
});

export const langTbnMonomers = new LanguageSupport(monomersLanguage);

const energiesParserWithMetadata = energiesParser.configure({
  props: [style],
});

const energiesLanguage = LRLanguage.define({
  parser: energiesParserWithMetadata,
  languageData: {
    commentTokens: { line: "#" },
  },
});

export const langTbnEnergies = new LanguageSupport(energiesLanguage);
